import MouseIcon           from "assets/images/mouse.svg";
import MouseIconBlack           from "assets/images/mouse-black.svg";
import styled, {keyframes} from "styled-components";

const move = keyframes`
    0% {
        transform: translateX(0%) translateY(calc(-50% + 3px));
    }
    50% {
        transform: translateX(0%) translateY(calc(-50% - 3px));
    }
    100% {
        transform: translateX(0%) translateY(calc(-50% + 3px));
    }
`;

export const Container = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 72px;
    text-align: center;
    z-index: 4;
    padding-left: 24px;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateX(0%) translateY(-50%);
        width: 14px;
        height: 22px;
        background-image: url(${({color}) => color === "#000" ? MouseIconBlack : MouseIcon });
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        animation: ${move} 2s ease infinite;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        bottom: 64px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        bottom: 80px;
        padding-left: 18px;

        &:after {
            width: 10px;
        }
    }
    @media screen and (max-width: 813px) and (orientation: landscape) {
        display: none;
    }
`;

export const Text = styled.span`
    color: ${({color}) => color ? color : "#fff"};
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 1.2rem;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 1rem;
    }
`;
