import { graphql, useStaticQuery } from "gatsby";

export const HeatingImage = () => {
  const images = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "images/cases/heating/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      detail: file(relativePath: { eq: "images/cases/heating/detail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      logo: file(relativePath: { eq: "images/cases/heating/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 260, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      list_bg: file(
        relativePath: { eq: "images/cases/heating/list/background.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      list_detail: file(
        relativePath: { eq: "images/cases/heating/list/detail.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      small: file(relativePath: { eq: "images/cases/heating/small.png" }) {
        childImageSharp {
          fluid(maxWidth: 510, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_1: file(
        relativePath: { eq: "images/cases/heating/content/hea_1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_2: file(
        relativePath: { eq: "images/cases/heating/content/hea_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_3: file(
        relativePath: { eq: "images/cases/heating/content/hea_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_4: file(
        relativePath: { eq: "images/cases/heating/content/hea_4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_5: file(
        relativePath: { eq: "images/cases/heating/content/hea_5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_1f: file(
        relativePath: { eq: "images/cases/heating/full/hea_1f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hea_2f: file(
        relativePath: { eq: "images/cases/heating/full/hea_2f.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return images;
};
