import Image from "gatsby-image";
import React from "react";
import {useAuthorsPhoto} from "./useAuthorsPhoto";

export const useAuthorsData = () => {
  const images = useAuthorsPhoto();

  return {
    MariuszTrzeciak:   {
      name:     'Mariusz Trzeciak',
      position: "Ceo & founder",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.MariuszTrzeciak.childImageSharp.fluid}
                />
    },
    DariaPyziak:       {
      name:     'Daria Pyziak',
      position: "Graphic Designer",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.DariaPyziak.childImageSharp.fluid}
                />
    },
    GrzegorzSowa:      {
      name:     'Grzegorz Sowa',
      position: "Project Manager",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.GrzegorzSowa.childImageSharp.fluid}
                />
    },
    KubaZmuda:         {
      name:     'Kuba Żmuda',
      position: "Marketing Specialist",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.KubaZmuda.childImageSharp.fluid}
                />
    },
    GabrielaBuszta:    {
      name:     'Gabriela Buszta',
      position: "Marketing Specialist",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.GabrielaBuszta.childImageSharp.fluid}
                />
    },
    AlexMechew:        {
      name:     'Alex Mechew',
      position: "Marketing Specialist",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.AlexMechew.childImageSharp.fluid}
                />
    },
    JustynaBak:        {
      name:     'Justyna Bąk',
      position: "Content Specialist",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.JustynaBak.childImageSharp.fluid}
                />
    },
    MarzenaKawalerska: {
      name:     'Marzena Kawalerska',
      position: "Marketing Manager",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.MarzenaKawalerska.childImageSharp.fluid}
                />
    },
    SylwiaBuksa:       {
      name:     'Sylwia Buksa',
      position: "Junior Content Specialist",
      image:
                <Image
                  alt=""
                  objectPosition={"center"}
                  objectFit={"contain"}
                  fluid={images.SylwiaBuksa.childImageSharp.fluid}
                />
    }
  }

}
