import React             from "react";
import {Container, Text} from "./styles";

let window_height;
const isBrowser = typeof window !== `undefined`;

if (isBrowser) {
  window_height = window.innerHeight;
}

const scrollDown = () => {
  window.scrollTo({
    top:      window_height - 74,
    behavior: "smooth",
  });
};

export const ViewMore = ({color}) => {
  return (
    <Container color={color}>
      <Text color={color} onClick={() => scrollDown()}>Zobacz więcej</Text>
    </Container>
  );
};
