import {graphql, useStaticQuery} from "gatsby";

export const useAuthorsPhoto = () =>
useStaticQuery(
  graphql`
      query {
          DariaPyziak: file(relativePath: { eq: "images/authors/daria-pyziak.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          GrzegorzSowa: file(relativePath: { eq: "images/authors/grzegorz-sowa.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          KubaZmuda: file(relativePath: { eq: "images/authors/kuba-zmuda.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          GabrielaBuszta: file(relativePath: { eq: "images/authors/gabriela-buszta.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          MariuszTrzeciak: file(relativePath: { eq: "images/authors/mariusz-trzeciak.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          AlexMechew: file(relativePath: { eq: "images/authors/alex-mechew.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          JustynaBak: file(relativePath: { eq: "images/authors/justyna-bak.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          MarzenaKawalerska: file(relativePath: { eq: "images/authors/marzena-kawalerska.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          SylwiaBuksa: file(relativePath: { eq: "images/authors/sylwia-buksa.png" }) {
              childImageSharp {
                  fluid(maxWidth: 100, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `
);
