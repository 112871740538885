import icon_1 from "../assets/images/services_1.svg";
import icon_2 from "../assets/images/services_2.svg";
import icon_3 from "../assets/images/services_3.svg";
import icon_4 from "../assets/images/services_4.svg";

export const data_offerServices = [
  {
    icon: icon_1,
    title: "Design",
    topics: [
      "Interfejsy stron i aplikacji",
      "- Branding",
      "- Ilustracje",
      "- Grafiki 2D i 3D",
    ],
    text:
      "Wyróżnij się w tłumie! Stworzymy spójną identyfikację wizualną Twojej firmy, która podkreśli Twoją indywidualność.",
    anchor: "/uslugi#web-design",
  },
  {
    icon: icon_2,
    title: "Marketing",
    topics: [
      "Social Media",
      "- Google Ads",
      "- Pozycjonowanie",
      "- Copywriting",
    ],
    text:
      "Chcesz być sławnym odkrywcą kosmosu? Poprowadzimy Twój marketing prosto do gwiazd!",
    anchor: "/uslugi/#marketing",
  },
  {
    icon: icon_3,
    title: "Strony i sklepy internetowe",
    topics: ["Strony WWW", "- Sklepy internetowe", "- Aplikacje"],
    text:
      "Z nami nie wpadniesz do czarnej dziury. Tworzymy profesjonalne i nowoczesne strony, sklepy i aplikacje. Stawiamy na jakość - używamy autorskich rozwiązań i najnowszych technologii.",
    anchor: "/uslugi#web-development",
  },
  {
    icon: icon_4,
    title: "Media",
    topics: ["Foto", "- Video", "- Animacje", "- TV/Radio/Prasa", "- Outdoor"],
    text:
      "Zabłyśnij w medialnej galaktyce! Autorskie animacje, fotografie i produkcja wideo to tylko część tego, co możemy dla Ciebie zrobić.",
    anchor: "/uslugi#media",
  },
];
