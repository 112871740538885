import SEO                                                         from "components/seo";
import {Services}                                                  from "components/Services";
import {Download, Text, TextBox}                                   from "components/Services/styles";
import {TitleL}                                                    from "components/Typography";
import React                                                       from "react";
import {Clients, ContactPerson, NewHero, NewSlider, Testimonials,} from "sections/Homepage";
import {useCaseSliderData}                                         from "shared/Hooks/CasesNew/useCaseSliderData";
import {useContactPersonData}                                      from "shared/Hooks/ContactPerson";
import OgImage                                                     from "src/assets/images/og.png";
import {data_offerServices}                                        from "../data/offerServices";

const IndexPage = () => {
  const data_portfolio = Object.values(useCaseSliderData());
  return (
    <>
      <SEO
        title="InteractiveVision - Agencja Interaktywna Rzeszów"
        description="Marketing internetowy, Social Media, Strony i sklepy internetowe, projektowanie graficzne - kompleksowa obsługa firm."
        image={OgImage}
      />
      <NewHero/>
      <Services data={data_offerServices}>
        <TextBox>
          <TitleL underline>Nasze możliwości</TitleL>
          <Text>
            Szukasz partnera do misji, która zakończy się podbiciem kosmosu?
            Dobrze trafiłeś! Dostarczamy kompleksowe usługi z zakresu
            marketingu. Sprawimy, że Twoja marka będzie bardziej rozpoznawalna i
            zwiększy swoje zyski.
          </Text>
          <Download
            rel="noopener"
            target="_blank"
            href="https://interactivevision.pl/uslugi/prezentacja-interactivevision.pdf"
          >
            Pobierz naszą prezentację
          </Download>
        </TextBox>
      </Services>
      <NewSlider data={data_portfolio}/>
      <Testimonials/>
      <Clients/>
      {/*<ContactPerson personData={useContactPersonData().jakub_furman}/>*/}
    </>
  );
};

export default IndexPage;
