import React             from "react";
import Image             from "gatsby-image";
import { useTeamPhotos } from "shared/Hooks/About/Team";
import {useAuthorsData}  from "src/shared/Hooks/Authors/useAuthorsData";
import { HeatingImage }  from "./HeatingImage";

export const HeatingData = () => {
  const images = HeatingImage();
  const team_images = useTeamPhotos();
  const authorsData = useAuthorsData();

  return {
    color: "#2E54AF",
    title: "Hituś",
    seo:
      "Opracowaliśmy koncepcję i wstępne szkice, końcowy projekt w wersji ilustrowanej oraz projekt stroju. Zleciliśmy również jego wykonanie. ",
    slug: "heating",
    hero: {
      title: "Hituś - brand hero marki Heating.",
      color: "#FFF",
      background: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.hero.childImageSharp.fluid}
        />
      ),
      detail: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.detail.childImageSharp.fluid}
        />
      ),
    },
    list: {
      background: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.list_bg.childImageSharp.fluid}
        />
      ),
      detail: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.list_detail.childImageSharp.fluid}
        />
      ),
    },
    small_photos: {
      first: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.small.childImageSharp.fluid}
        />
      ),
    },
    what_we_did: {
      title: "Od szkicu do żywego bohatera",
      technologies: ["Brand hero", "Ilustracja", "Projekt stroju"],
      text:
        "Dla Heatingu stworzyliśmy brand hero - Hitusia, który stał się twarzą firmy. Jego celem jest kreowanie pozytywnego wizerunku oraz skrócenie dystansu między marką a jej klientami i partnerami. Opracowaliśmy koncepcję i wstępne szkice, końcowy projekt w wersji ilustrowanej oraz projekt stroju. Zleciliśmy również jego wykonanie. Hituś to bohater, który reprezentuje Heating na giełdach handlowych i eventach branżowych. ",
      list: [
        "Wymyśliliśmy koncepcje brand hero",
        "Przygotowaliśmy autorską ilustrację",
        "Wykonaliśmy projeklt maskotki 3D",
        "Zleciliśmy uszycie stroju postaci",
      ],
    },
    headers: {
      first: "Rozgrzewający brand hero",
      second: "Przewaga nad konkurencją",
    },
    logo: {
      first: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.logo.childImageSharp.fluid}
        />
      ),
    },
    content_images: {
      first: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.hea_1.childImageSharp.fluid}
        />
      ),
      second: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.hea_2.childImageSharp.fluid}
        />
      ),
      third: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.hea_3.childImageSharp.fluid}
        />
      ),
      fourth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.hea_4.childImageSharp.fluid}
        />
      ),
      fifth: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"contain"}
          fluid={images.hea_5.childImageSharp.fluid}
        />
      ),
    },
    full_images: {
      first: {
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.hea_1f.childImageSharp.fluid}
          />
        ),
      },
      second: {
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"contain"}
            fluid={images.hea_2f.childImageSharp.fluid}
          />
        ),
      },
    },
    text_boxes: {
      first: {
        title: "Maskotka, która podgrzewa atmosferę",
        text:
          "Hituś swoim wyglądem nawiązuje do branży grzewczej, w której działa firma. Przypomina płomień, który symbolizuje ofertę marki. Maskotka jest wesoła i przyjaźnie nastawiona do otoczenia, dzięki czemu odzwierciedla wartości, którymi kieruje się Heating. Jego wizerunek przywołuje w świadomości klientów obraz całej marki. ",
      },
      second: {
        title: "Kolorystyka spójna z wizerunkiem marki",
        text:
          "Hituś został zaprojektowany zgodnie z kolorystyką, którą marka wykorzystuje w komunikacji: pomarańczowy, niebieski i biały. Dzięki temu wizerunek brand hero jest zgodny z całym brandingiem i jednoznacznie się z nią kojarzy.",
      },
      third: {
        title: "Nie tylko brand hero...",
        text:
          "Oprócz zaprojektowania brand hero stworzyliśmy również gadżety okazjonalne. Breloczki i podkładki pod kubki przedstawiają Hitusia i są skutecznym nośnikiem reklamy. Można wykorzystać je podczas imprez i eventów branżowych. Zaprojektowaliśmy i wykonaliśmy także katalog okazjonalny, w którym przedstawiliśmy ofertę marki w atrakcyjnej dla odbiorcy formie. ",
      },
    },
    team: [
      authorsData.DariaPyziak,
      authorsData.GrzegorzSowa
    ],
    marketing: {
      counts: [
        {
          count: "20%",
          title: "Zwiększona konwersja",
        },
        {
          count: 2000,
          title: "Polubień w 1 miesiąc",
        },
        {
          count: "53%",
          title: "zwiększona sprzedaż",
        },
      ],
      team: [
        {
          name: "Patrycja Bilska",
          position: "Project Manager",
          image: (
            <Image alt="" fluid={team_images.Patrycja.childImageSharp.fluid} />
          ),
        },
        {
          name: "Rafał Skórka",
          position: "Frontend Developer",
          image: (
            <Image alt="" fluid={team_images.Rafal.childImageSharp.fluid} />
          ),
        },
        {
          name: "Patryk Białas",
          position: "Graphic designer",
          image: (
            <Image alt="" fluid={team_images.Patryk.childImageSharp.fluid} />
          ),
        },
        {
          name: "Rafał Krawiec",
          position: "Backend Developer",
          image: (
            <Image alt="" fluid={team_images.Krawczyk.childImageSharp.fluid} />
          ),
        },
        {
          name: "Jakub Żmuda",
          position: "Marketing Specialist",
          image: (
            <Image alt="" fluid={team_images.Kuba.childImageSharp.fluid} />
          ),
        },
        {
          name: "Jakub Furman",
          position: "Sales Manager",
          image: (
            <Image alt="" fluid={team_images.Furman.childImageSharp.fluid} />
          ),
        },
      ],
    },
  };
};
