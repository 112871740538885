import CaseName from "shared/Types/cases";
import { MercedesData } from "./Mercedes/MercedesData";
import { BeksinskiData } from "./Beksinski/BeksinskiData";
import { PizzaData } from "./Pizza/PizzaData";
import { HeatingData } from "./Heating/HeatingData";

export const useCaseSliderData = () => ({
  [CaseName.MERCEDES]: MercedesData(),
  [CaseName.PIZZA]: PizzaData(),
  [CaseName.BEKSINSKI]: BeksinskiData(),
  [CaseName.HEATING]: HeatingData(),
});
